import { 
  Meeting, ZoomSettings, LeaderList, Calendar, MeetingRoom, PresetLocations, User, 
} from "../../../store";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { CabModal } from "@CabComponents/CabModal";
import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { Box, FormControl, FormLabel, Grid, Typography } from "@mui/material";
import { CabDropdown } from "@CabComponents/CabDropdown";
import CabMeetingLocationDropdown from "@CabComponents/CabMeetingLocationDropdown";
import { CabTooltip } from "@CabComponents/CabTooltip";
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { getTemplateVars } from "../../../utils/scheduleUtils";
import { transformMarkupToSimpleTemplate, transformSimpleTemplateToMarkup } from "@CabComponents/CabTextTokenInput";
import { PROVIDER } from "../../../constants";
import { useDeepCompareEffect } from "react-use";
import { useAutoSubmit } from "../../../utils/formUtils";
import { uniqBy } from "lodash-es";
import { IoCheckmark, IoCloseOutline, IoAddOutline } from "react-icons/io5";
import { checkForMicrosoftGrant } from "../../../utils/authUtils";


export interface LocationsFormInput {
  locations?: ({
    conference?: { provider: number | null; leader: number | null },
    locationText?: string;
    room?: number;
    presetLocation?: number;
    none?: boolean;
  })[];
}

interface CalendarFormInput {
  selectedCalendar?: number;
}

interface EditLocationsModalProps {
  meeting: Meeting;
  zoomSettings: {
    [settingsId: string]: ZoomSettings;
  };
  user: User | null | undefined;
  leaders: LeaderList;
  leaderCalendarOptions: Calendar[];
  onChangeValues: (meetingPartial: Partial<Meeting>) => Promise<void>;
  onAddZoom: () => void;
  isOwner: boolean;
  calendars: Calendar[]
  meetingRooms: { [id: string]: MeetingRoom }
  presetLocations: PresetLocations;
  onModalClose: () => void;
  modalOpen: boolean;
}

const EditMeetingLocationsModal = ({
  meeting, zoomSettings, user, leaders, leaderCalendarOptions, onChangeValues, onAddZoom, isOwner, 
  calendars, meetingRooms, presetLocations, modalOpen, onModalClose
}: EditLocationsModalProps): ReactElement => {
  const [saving, setSaving] = useState(false);

  const calendar = useMemo(
    () => calendars.find(
      (cal) => meeting.booking_calendar === cal.id), [meeting.booking_calendar, calendars]
  );

  const templateVars = useMemo(() => {
    return getTemplateVars(meeting.questions);
  }, [meeting.questions]);

  const templateNameLookup = useMemo(() => Object.entries(templateVars)
    .map(([k, v]) => ({ [k]: v.replaceText }))
    .reduce((a, b) => ({ ...a, ...b }), {})
  , [templateVars]);

  const locDefaultValues: LocationsFormInput = useMemo(() => {
    const locations: LocationsFormInput['locations'] = [
      ...(meeting.conference_provider && meeting.conference_provider !== PROVIDER.NO_PROVIDER.id
        ? [{ conference: { provider: meeting.conference_provider, leader: meeting.conference_leader || null } }]
        : []
      ),
      ...meeting.rooms.map(roomId => ({ room: roomId })),
      ...meeting.location_presets.map(presetLoc => ({ presetLocation: presetLoc })),
      ...(meeting.locations || []).map(loc => ({
        locationText: transformSimpleTemplateToMarkup(loc, templateNameLookup),
      })),
    ];

    if (locations.length === 0) {
      locations.push({ none: true });
    }

    return {
      locations,
    };
  }, [meeting.conference_leader, meeting.conference_provider, meeting.locations,
    meeting.rooms, meeting.location_presets, templateNameLookup]);

  const defaultValues: CalendarFormInput = useMemo(() => {
    return {
      selectedCalendar: meeting.booking_calendar,
    };
  }, [meeting.booking_calendar]);

  const locationsForm = useForm<LocationsFormInput>({ defaultValues: locDefaultValues });
  const calendarForm = useForm<CalendarFormInput>({ defaultValues, mode: 'onBlur' });
  const { control, reset } = calendarForm;

  const { locations } = locationsForm.watch();

  const {
    fields: locationFields, append, remove,
  } = useFieldArray({ control: locationsForm.control, name: 'locations' });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const locReset = locationsForm.reset;
  useDeepCompareEffect(() => {
    locReset(locDefaultValues);
  }, [locDefaultValues, locReset]);

  const handleLocationsSubmit: SubmitHandler<LocationsFormInput> = useCallback(async (data) => {
    const [conference] = data.locations?.filter(loc => loc?.conference) || [];
    const conferenceProvider: number | null | undefined = conference?.conference?.provider || null;
    const conferenceLeader: number | null | undefined = conference?.conference?.leader || null;

    setSaving(true);

    const locationsToSubmit = data.locations?.filter(loc => loc?.locationText != null)
      .map(loc => loc?.locationText ? transformMarkupToSimpleTemplate(loc.locationText) : '') as string[];
    const rooms = data.locations?.filter(loc => loc?.room != null && loc.room !== -1)
      .map(loc => loc?.room) as number[];
    const presetLocationsToSubmit = data.locations?.filter(loc => loc?.presetLocation != null)
      .map(loc => loc?.presetLocation) as number[];

    await onChangeValues({
      conference_provider: conferenceProvider,
      conference_leader: conferenceLeader,
      locations: locationsToSubmit,
      rooms,
      location_presets: presetLocationsToSubmit,
    });

    setSaving(false);
  }, [onChangeValues]);

  const handleSubmit: SubmitHandler<CalendarFormInput> = useCallback(async (data) => {
    setSaving(true);

    await onChangeValues({
      booking_calendar: data.selectedCalendar,
    });

    setSaving(false);
  }, [onChangeValues]);

  useAutoSubmit({
    control: calendarForm.control,
    memoizedHandleSubmit: handleSubmit,
    fields: ["selectedCalendar"],
    handleSubmit: calendarForm.handleSubmit,
    getValues: calendarForm.getValues,
    reset: calendarForm.reset
  });

  let calendarOptions = leaderCalendarOptions.map(cal => ({
    value: cal.id, label: cal.summary
  }));

  if (meeting.calendar_info?.calendar_name) {
    calendarOptions = uniqBy([
      ...calendarOptions,
      { value: meeting.calendar_info.id, label: meeting.calendar_info.calendar_name },
    ], cal => cal.value);
  }

  if (!user) return <></>;

  return (
    <CabModal
      open={modalOpen}
      onClose={onModalClose}
      closeIcon
      title={'Edit Locations'}
      actionButtons={(
        <CabButton
          onClick={onModalClose}
          icon={<CabIcon alt="Done" Icon={IoCheckmark} />}
        >
          Done
        </CabButton>
      )}
    >
      <Grid container>
        <Grid xs={12} item marginTop={2}>
          <Controller name="selectedCalendar" control={control} render={({ field: { ref, ...field } }) => (
            <FormControl sx={{ width: "100%" }}>
              <FormLabel>Calendar</FormLabel>
              <CabTooltip
                title={isOwner ? '' : 'Only meeting owner may edit this field'}
                placement="top"
                wrapWithSpan
              >
                <CabDropdown<number>
                  {...field}
                  options={calendarOptions}
                  disabled={!isOwner}
                  sx={{ width: '100%' }}
                />
              </CabTooltip>
            </FormControl>
          )} />
        </Grid>
        <Grid xs={12} container item marginTop={2} marginBottom={'2px'}>
          <Typography>Location(s) and Conference Tool</Typography>
        </Grid>
        <Grid xs={12} container item spacing={2}>
          {locationFields.map((locField, idx) => (
            <Controller
              key={locField.id}
              name={`locations.${idx}`}
              control={locationsForm.control}
              render={({ field: { ref, ...field } }) => (
                <>
                  <Grid xs={locations && locations.length > 1 ? 11.3 : 12} item>
                    <CabMeetingLocationDropdown
                      {...field}
                      onChange={loc => {
                        field.onChange(loc);
                        if (!('locationText' in loc) && loc.room !== -1) {
                          locationsForm.handleSubmit(handleLocationsSubmit)();
                        }
                      }}
                      onBlurOtherField={() => locationsForm.handleSubmit(handleLocationsSubmit)()}
                      meeting={meeting}
                      meetingBookingCalendar={calendar}
                      user={user}
                      leaders={leaders}
                      zoomSettings={zoomSettings}
                      isOwner={isOwner}
                      presetLocations={{...presetLocations, ...(meeting.location_presets_data || {})}}
                      meetingRooms={{...meetingRooms, ...(meeting.rooms_data || {})}}
                      onAddZoom={onAddZoom}
                      templateVars={templateVars}
                      // only allow one virtual conference location
                      disableConferenceOptionsTooltip={locations?.some(loc => loc?.conference)
                        ? 'Only one conference integration can be set per meeting' : undefined}
                      disabledLocationPresetIds={locations?.filter(loc => loc?.presetLocation)
                        .map(loc => loc?.presetLocation) as number[]}
                      disabledMeetingRoomIds={locations?.filter(loc => loc?.room).map(loc => loc?.room) as number[]}
                      hasMicrosoftGrant={user ? checkForMicrosoftGrant(user.oauth_grant_details) : undefined}
                    />
                  </Grid>

                  {isOwner && locations && locations.length > 1 && (
                    <Grid xs={0.7} item marginLeft={-1.6}>
                      <CabIcon
                        Icon={IoCloseOutline}
                        onClick={() => {
                          remove(idx);
                          locationsForm.handleSubmit(handleLocationsSubmit)();
                        }}
                        sx={{ fontSize: 30, paddingTop: 0.5, paddingLeft: '4px' }}
                      />
                    </Grid>
                  )}
                </>
              )} />
          ))}

          <Grid xs={12} item sx={{ marginBottom: 1}}>
            <Box sx={{ width: "100%" }} display="flex" flexDirection="row">
              <CabButton
                buttonType="tertiary"
                disabled={saving}
                onClick={() => append({ none: true })}
                icon={<CabIcon Icon={IoAddOutline} />}
                sx={{ border: 1, borderColor: 'transparent' }}
              >
                Add Meeting Location
              </CabButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </CabModal>
  );
};

export default EditMeetingLocationsModal;