import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Typography, styled, Box, Alert, FormLabel } from '@mui/material';
import { DateTime } from 'luxon';
import CabinetPage from '../../components/Common/CabinetPage';
import { Header, PollTable, PollTableLegend, StatusDropdown } from '../../components/Meeting/GroupScheduling';
import { getNumResponses, humanReadableDuration, TimeZone } from '../../utils/scheduleUtils';
import {
  CabButton, CabCollapseMenu, CabControlLabel, CabIcon, CabModal, CabSwitch, CabTimezoneInput,
} from '@CabComponents';
import { CreateUser, MeetingPollPriority, MeetingQuestion, 
  MeetingQuestionAnswer, MeetingSlot, MeetingStatus, ParticipantsWithSelections, SelectedSlot, SelectedSlots 
} from '../../store/schedule/types';
import PollQuestionResults from './PollQuestionResults';
import colors from '../../colors';
import { MEETING_STATUS_LABELS, PAGE_URL } from '../../constants';
import { CabTabs } from '@CabComponents/CabTabs';
import { ActionMenu } from '../../components/Meeting/ActionMenu';
import { useNavigate } from 'react-router';
import BookMeetingModal from '../../components/Schedule/BookMeetingModal';
import CabSpinner from '@CabComponents/CabSpinner';
import { IoArrowBack, IoEllipsisHorizontal, IoTimeOutline } from 'react-icons/io5';


export type ConvertedSlots = {
  converted: SelectedSlot;
  index: number;
  start: DateTime;
  end: DateTime;
  priority?: MeetingPollPriority;
}[];

const separateSlotsIntoDays = (convertedSlots: ConvertedSlots) => {
  return convertedSlots.reduce((slotSegments: (typeof convertedSlots)[], slot, i, arr) => {
    if (slot.converted.start.toISODate() !== arr[i - 1]?.converted.start.toISODate()) {
      return [...slotSegments, [slot]];
    }
    const segments = slotSegments.slice(0, -1);
    const prevSegment = slotSegments[slotSegments.length - 1];
    return [...segments, [...prevSegment, slot]];
  }, []);
};

export type PollInfo = {
  id: number;
  from: CreateUser | undefined;
  name: string;
  description: string;
  durationMinutes: number;
  expectedParticipants: number;
  slots: SelectedSlots;
  participants: ParticipantsWithSelections;
  startTime?: string|null;
  status: number;
  questions?: {[id: number]: MeetingQuestion}
  questionAnswers?: {[id: number]: MeetingQuestionAnswer}
  locations?: string[] | null;
  calendarName?: string;
};

interface PollProps {
  inAdmin?: boolean
  pollInfo: PollInfo;
  timezone?: TimeZone;
  onConvertToMeeting?: (start: DateTime, end: DateTime, timezone?: string) => void;
  onEdit: () => void;
  onDuplicate: () => void;
  onDelete: () => void;
  onShare:  () => void;
  onCopyLink:  () => void;
  onUpdateStatus: (status: number) => Promise<void>;
  meetingSlots: MeetingSlot[],
  questionAnswers?: {[id: number]: MeetingQuestionAnswer}
  onExport: (timeslotSegments: ConvertedSlots[]) => string[][];
  showSplitByDay?: boolean;
  onRemoveTimes?: (
    timeSlotsToRemove: { start: DateTime; end: DateTime }[],
    timeSlotsToKeep: { start: DateTime; end: DateTime }[],
  ) => void;
}


const PollQuestionResultsContainer = styled("div", {label: "PollQuestionResultsContainer"})({
  marginTop: 24
});

const Poll = ({
  inAdmin,
  pollInfo: {
    id, from, name, description, durationMinutes,
    expectedParticipants, participants, slots, startTime, status,
    questions
  },
  timezone,
  onConvertToMeeting,
  onEdit,
  onShare,
  onCopyLink,
  onDuplicate,
  onDelete,
  onUpdateStatus,
  onExport,
  meetingSlots,
  questionAnswers,
  showSplitByDay,
  onRemoveTimes,
}: PollProps) => {
  const navigate = useNavigate();
  const [separateDays, setSeparateDays] = useState(false);
  const [showEmailsOverride, setShowEmailsOverride] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState<string | null>(timezone?.name || null);
  const [slotIndexesToRemove, setSlotIndexesToRemove] = useState<Set<number>>(new Set());
  const [showRemoveSlotsConfirmationModal, setShowRemoveSlotsConfirmationModal] = useState(false);
  const [removeTimesMode, setRemoveTimesMode] = useState(false);
  const [showTimesWithoutVotes, setShowTimesWithoutVotes] = useState(true);

  const convertedSlots: ConvertedSlots = useMemo(() => (
    slots.filter(slot => !!meetingSlots.find(
      meetingSlot => {
        return DateTime.fromISO(
          meetingSlot.start_date).toMillis() <= slot.start.toMillis() &&
          DateTime.fromISO(meetingSlot.end_date).toMillis() >= slot.end.toMillis();
      }
    )).map((slot, i) => ({
      ...slot,
      converted: selectedTimezone ? {
        ...slot,
        start: slot.start.setZone(selectedTimezone),
        end: slot.end.setZone(selectedTimezone)
      } : slot,
      index: i,
    }))
  ), [slots, selectedTimezone, meetingSlots]);

  const slotsToShow = useMemo(() => convertedSlots.filter(slot => (
    removeTimesMode && showTimesWithoutVotes
      ? slot
      : participants.some(p => p.selectedSlots.some(ss => ss.start.toMillis() === slot.converted.start.toMillis()))
  )), [convertedSlots, participants, removeTimesMode, showTimesWithoutVotes]);

  const tabs = [{ label: "Meeting Times" }];
  if (Object.values(questions || {}).length > 0) {
    tabs.push({ label: "Poll Question Responses" });
  }

  // chunk into separate days if flag is true
  const timeslotSegments = separateDays ? separateSlotsIntoDays(slotsToShow) : [slotsToShow];

  let bookDate: DateTime | null = null;
  if (startTime) {
    bookDate = DateTime.fromISO(startTime);
  }
  const bookedTime = bookDate ? `${bookDate.toLocaleString({
    weekday: 'long', month: 'long', day: 'numeric'})} at ${bookDate.toFormat('h:mma').toLowerCase()}` : '';

  useEffect(() => {
    if (timezone?.name) {
      setSelectedTimezone(timezone.name);
    }
  }, [timezone?.name]);

  const handleTimezoneChange = (tzName: string | null) => {
    setSelectedTimezone(tzName);
  };

  const handleRemoveTimesMode = () => {
    setRemoveTimesMode(true);
    setSlotIndexesToRemove(new Set());
  };

  const handleCancelRemoveTimesMode = () => {
    setSlotIndexesToRemove(new Set());
    setRemoveTimesMode(false);
  };

  const handleSaveRemovedTimes = () => {
    if (slotIndexesToRemove.size > 0 && onRemoveTimes) {
      const slotIndexesToKeep = convertedSlots.filter(s => !slotIndexesToRemove.has(s.index)).map(s => s.index);

      onRemoveTimes(
        [...slotIndexesToRemove].sort((a, b) => a - b)
          .map(si => convertedSlots.find(s => s.index === si)?.converted)
          .filter((s): s is { id: number; start: DateTime; end: DateTime; priority: MeetingPollPriority } => !!s),
        [...slotIndexesToKeep].sort((a, b) => a - b)
          .map(si => convertedSlots.find(s => s.index === si)?.converted)
          .filter((s): s is { id: number; start: DateTime; end: DateTime; priority: MeetingPollPriority } => !!s),
      );
    }

    setShowRemoveSlotsConfirmationModal(false);
    setRemoveTimesMode(false);
    setSlotIndexesToRemove(new Set());
  };

  const handleRemoveSlot = (index: number) => {
    setSlotIndexesToRemove(new Set([...slotIndexesToRemove, index]));
  };

  const handleRemoveAllTimesWithoutVotes = () => {
    const curSlotIndexesToRemove = convertedSlots.filter(slot => (
      !participants.some(p => p.selectedSlots.some(ss => ss.start.toMillis() === slot.converted.start.toMillis()))
    )).map(s => s.index);
    setSlotIndexesToRemove(new Set(curSlotIndexesToRemove));
    setShowRemoveSlotsConfirmationModal(true);
  };

  const handleShowTimesWithoutVotes = (enable: boolean) => {
    setShowTimesWithoutVotes(enable);
    // we need to reset this or the indices won't align correctly
    setSlotIndexesToRemove(new Set());
  };

  return (
    <Box margin={4}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <CabButton
          buttonType="tertiary"
          icon={<CabIcon Icon={IoArrowBack} />}
          sx={{ marginBottom: 2, color: colors.black800, borderColor: colors.black400 }}
          size="small"
          onClick={() => navigate(PAGE_URL.POLL_RESULTS)}
        >
          All polls
        </CabButton>

        <Box flexDirection="row" gap={1} display={{ xs: 'flex', sm: 'flex', md: 'none' }}>
          <StatusDropdown status={status} onUpdateStatus={onUpdateStatus} sx={{ height: 30 }} />
          <ActionMenu
            isPoll={true}
            onEdit={onEdit}
            onDelete={onDelete}
            onDuplicate={onDuplicate}
            onShare={onShare}
            onCopyLink={onCopyLink}
            onExport={() => onExport(timeslotSegments)} filename={name}
            target={<CabIcon
              Icon={IoEllipsisHorizontal}
              sx={{fontSize: 25, paddingLeft: 1, paddingRight: 1, 
                backgroundColor: colors.black100, height: 30 }}
            />}
            sx={{ width: 40, borderColor: colors.black200, borderWidth: 1,
              borderStyle: 'solid', borderRadius: 1, height: 30 }} />
        </Box>
      </Box>

      <Box display="flex" alignItems="start" width="100%">
        <Header
          creatorName={from ? `${from.first_name} ${from.last_name}` : ''}
          creatorEmail={from?.email || ''}
          name={name}
          description={description}
          durationText={humanReadableDuration(durationMinutes)}
          expectedParticipants={expectedParticipants}
          participantLength={getNumResponses(participants)}
          onUpdateStatus={onUpdateStatus}
        />

        <Box flexDirection="row" gap={1} display={{ xs: 'none', sm: 'none', md: 'flex' }}>
          {!removeTimesMode ? (
            <>
              <StatusDropdown status={status} onUpdateStatus={onUpdateStatus} />
              <CabCollapseMenu
                popOverTitle=""
                target={
                  <CabButton
                    sx={{
                      textWrap: 'nowrap', backgroundColor: colors.black100, color: colors.black900,
                      borderColor: colors.black200, borderWidth: 1, borderStyle: 'solid',
                      '&:hover': { backgroundColor: colors.white900 }, height: 40
                    }}
                    icon={<CabIcon Icon={IoTimeOutline} />}
                  >
                    Remove Times
                  </CabButton>
                }
                buttons={
                  <>
                    <CabButton 
                      buttonType="text"
                      onClick={handleRemoveAllTimesWithoutVotes}
                      sx={{ maxWidth: 230, textAlign: 'start', color: colors.black900 }}
                    >
                      <Box display='flex'>
                        <Typography variant='h2' fontSize={15}>Remove Times Without Votes</Typography>
                      </Box>
                      <Typography variant="body2" color={colors.black800}>
                        Removes all selected times that have not been voted on yet
                      </Typography> 
                    </CabButton>
                    <CabButton 
                      buttonType="text"
                      onClick={handleRemoveTimesMode}
                      sx={{ maxWidth: 230, textAlign: 'start', color: colors.black900 }}
                    >
                      <Box display='flex'>
                        <Typography variant='h2' fontSize={15}>Remove Specific Times</Typography>
                      </Box>
                      <Typography variant="body2" color={colors.black800}>
                        Select specific times you want to remove
                      </Typography> 
                    </CabButton>
                  </>
                }
              />
              <ActionMenu
                isPoll={true}
                onEdit={onEdit}
                onDelete={onDelete}
                onDuplicate={onDuplicate}
                onShare={onShare} 
                onCopyLink={onCopyLink}
                onExport={() => onExport(timeslotSegments)} filename={name}
                sx={{ 
                  width: 40, 
                  borderColor: colors.black200, 
                  borderWidth: 1,
                  borderStyle: 'solid', 
                  borderRadius: 1, 
                  height: 'auto' 
                }} 
                target={<CabIcon
                  Icon={IoEllipsisHorizontal}
                  sx={{
                    fontSize: 25, 
                    paddingLeft: 1, 
                    paddingRight: 1, 
                    backgroundColor: colors.black100, 
                    display: "flex",
                    alignItems: "center",
                    height: 38 
                  }}
                />} />
            </>
          ) : (
            <>
              <Box display="flex" alignItems="center">
                <FormLabel sx={{ whiteSpace: 'nowrap' }}> Show only times with votes</FormLabel>
                <CabSwitch
                  checked={!showTimesWithoutVotes}
                  onChange={() => handleShowTimesWithoutVotes(!showTimesWithoutVotes)}
                />
              </Box>
              <CabButton
                buttonType="secondary"
                sx={{ textWrap: 'nowrap', height: 40, fontSize: 16 }}
                size="large"
                onClick={handleCancelRemoveTimesMode}
              >
                Cancel
              </CabButton>
              <CabButton
                sx={{ textWrap: 'nowrap', height: 40, fontSize: 16 }}
                size="large"
                onClick={() => setShowRemoveSlotsConfirmationModal(true)}
              >
                Save
              </CabButton>
            </>
          )}
        </Box>
      </Box>

      <CabTabs tabs={tabs} align="left" hideDivider sx={{ marginTop: 2 }}>
        <Box marginTop={2}>
          {status && (status !== MeetingStatus.PENDING) && (
            <Box marginBottom={1}>
              {status === MeetingStatus.SCHEDULED ? (
                <Alert severity='info' sx={{maxWidth: 'fit-content', marginBottom: 1, minWidth: 150}}>
                  {bookedTime ? (
                    <span>This meeting has been <b>{MEETING_STATUS_LABELS[status]}</b> for <b>{bookedTime}</b></span>
                  ) : (
                    <span>This meeting has been <b>{MEETING_STATUS_LABELS[status]}</b> outside of Cabinet</span>
                  )}
                </Alert>
              ) : (
                <Alert severity='info' sx={{maxWidth: 300}}>
                  This meeting has been <b>{MEETING_STATUS_LABELS[status]}</b>
                </Alert>
              )}
            </Box>
          )}

          <Box sx={{ 
            display: "flex", 
            flexDirection: { xs: "column", sm: "row" }, 
            justifyContent: 'space-between',
            marginBottom: 1,
            gap: 1,
          }}>
            {showSplitByDay && slots.length > 0 ? (
              <CabControlLabel
                sx={{ marginBottom: { xs: 3, sm: 0 }, marginTop: { xs: 2, sm: 0 }, marginRight: 2 }}
                label="Split by Day"
                labelPlacement='end'
                control={<CabSwitch checked={separateDays} onChange={() => {
                  setSeparateDays(!separateDays);
                }} />}
              />
            ) : (
              <div/>
            )}

            <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} gap={4} flex={1}
              justifyContent={(showSplitByDay && slots.length > 0) ? 'end' : 'start'} alignItems={{ sm: "center" }}
            >
              <CabTimezoneInput
                label='Timezone'
                value={selectedTimezone || undefined}
                required={true}
                size={'small'}
                // defaultValue={templateTimezone ? templateTimezone : ''}
                onChange={handleTimezoneChange}
                sx={{ flexGrow: 1, minWidth: 200, maxWidth: 400 }}
              />
              <PollTableLegend />
            </Box>
          </Box>

          {participants.length > 0 ? (
            timeslotSegments.map((slotsItr, i) => (
              <PollTable
                // this forces a necessary re-render when we switch from normal view to split-by-day
                key={timeslotSegments.length > 1 ? (slotsItr.at(0)?.converted.start.toISO() || i) : 'all'}
                inAdmin={inAdmin}
                votable={false}
                name={from?.email || ''}
                participants={participants}
                timezoneConvertedSlots={slotsItr}
                onCheckSlot={() => console.log('N/A')}
                onConvertToMeeting={
                  onConvertToMeeting && ((start, end) => onConvertToMeeting(start, end, selectedTimezone || undefined))
                }
                onRemoveSlot={removeTimesMode ? handleRemoveSlot : undefined}
                slotIndexesToRemove={slotIndexesToRemove}
                startTime={startTime}
                inProgress={status === MeetingStatus.PENDING}
                highlightTopPicks
                showEmailsOverride={showEmailsOverride}
                onShowEmailsOverride={setShowEmailsOverride}
                sx={{ marginTop: 4 }}
              />
            ))
          ) : (
            <Box width='100%' paddingTop={4} textAlign='center' sx={{ fontSize: 14 }}>
              There are currently no responses for this poll
            </Box>
          )}
        </Box>
        {Object.values(questions || {}).length > 0 &&
          <PollQuestionResultsContainer>
            <PollQuestionResults
              questions={questions} questionAnswers={questionAnswers} participants={participants}
            />
          </PollQuestionResultsContainer>
        }

      </CabTabs>
      
      <CabModal
        open={showRemoveSlotsConfirmationModal}
        onClose={() => setShowRemoveSlotsConfirmationModal(false)}
        title="Confirm slots to remove"
        text={`The following times will be removed from this poll. New voters will not be able to select them, and
        any existing votes for those times will be removed.`}
        closeIcon
        actionButtons={
          <Box>
            <CabButton buttonType="secondary" sx={{ marginRight: 1 }}
              onClick={() => setShowRemoveSlotsConfirmationModal(false)}
            >
              Cancel
            </CabButton>
            <CabButton onClick={handleSaveRemovedTimes}>
              Remove Times
            </CabButton>
          </Box>
        }
      >
        <ul>
          {[...slotIndexesToRemove].sort((a, b) => a - b)
            .map(si => (
              <li key={si}>
                {convertedSlots[si].converted.start.toFormat('MMM dd, yyyy @ h:mm a')}
                {convertedSlots[si].converted.end.toFormat(' - h:mm a')}
              </li>
            ))
          }
        </ul>
      </CabModal>
    </Box>
  );
};

export interface PollResultsProps {
  inAdmin?: boolean
  poll: PollInfo;
  hasGrant: boolean;
  loading: boolean;
  onEdit: (id: number) => void;
  handleDuplicate: (id: number) => void;
  handleDeletePoll: (id: number) => void;
  handleCopyLink: (id: number) => void;
  onUpdateStatus: (id: number, status: number) => Promise<void>;
  onExport: (timeslotSegments: ConvertedSlots[]) => string[][];
  pollId?: number;
  meetingSlots: MeetingSlot[]
  questionAnswers?: {[id: number]: MeetingQuestionAnswer}
  showSplitByDay?: boolean;
  onOpenShareMeetingModal: () => void;
  timezone?: TimeZone;
  onRemoveTimes?: (
    timeSlotsToRemove: { start: DateTime; end: DateTime }[],
    timeSlotsToKeep: { start: DateTime; end: DateTime }[],
  ) => void;
}

interface PollConfirmation {
  poll: PollInfo, start: DateTime, end: DateTime
}

const PollResults = ({
  poll, hasGrant, onEdit, handleDuplicate, onUpdateStatus, onExport,
  loading, meetingSlots, inAdmin, questionAnswers, handleDeletePoll, handleCopyLink, showSplitByDay,
  onOpenShareMeetingModal, timezone, onRemoveTimes
}: PollResultsProps): ReactElement => {
  const [pollConfirmation, setPollConfirmation] = useState<PollConfirmation | undefined>();

  useEffect(() => {
    if (pollConfirmation) {
      setPollConfirmation({poll, start: pollConfirmation.start, end: pollConfirmation.end});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poll]);

  const handleConvertToMeeting = (start: DateTime, end: DateTime, tz?: string) => {
    setPollConfirmation({
      poll,
      start: tz ? start.setZone(tz) : start,
      end: tz ? end.setZone(tz) : end,
    });
  };

  const handleEdit = (pollId: number) => {
    onEdit(pollId);
  };

  return (
    <CabinetPage
      pageName={'Poll Results'}
      headerContent={<>
        <Typography variant="h1">
          Poll Results
        </Typography>
      </>}
    >
      {!loading && (
        <Poll
          inAdmin={inAdmin}
          showSplitByDay={showSplitByDay}
          pollInfo={poll}
          // if by setting onConvertToMeeting to undefined
          // ui to book the meeting will be blocked
          onConvertToMeeting={hasGrant ? handleConvertToMeeting : undefined}
          onEdit={() => handleEdit(poll.id)}
          onDuplicate={() => handleDuplicate(poll.id)}
          onDelete={() => handleDeletePoll(poll.id)}
          onShare={onOpenShareMeetingModal}
          onCopyLink={() => handleCopyLink(poll.id)}
          onExport={onExport}
          onUpdateStatus={(status: number) => onUpdateStatus(poll.id, status)}
          meetingSlots={meetingSlots}
          questionAnswers={questionAnswers}
          timezone={timezone}
          onRemoveTimes={onRemoveTimes}
        />
      )}
      <Box
        hidden={!loading}
        sx={{
          position: 'absolute',
          zIndex: 200,
          height: '50%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: 0.5,
          backgroundColor: colors.white900,
        }}
      >
        <CabSpinner scale={2} color='inherit' />
      </Box>
      <BookMeetingModal
        open={!!pollConfirmation}
        meetingId={poll.id}
        start={pollConfirmation?.start}
        end={pollConfirmation?.end}
        onClose={() => setPollConfirmation(undefined)}
      />
    </CabinetPage>
  );
};

export default PollResults;
